<template>
  <v-container fluid tag="section">
    <v-row justify="center">
      <v-col cols="12" md="3">
        <!--  -->
        <v-card class="pa-4">
          <v-card-text v-if="user">
            <v-row>
              <v-col cols="12" md="6">
                <strong>{{ $phraser.set('client:') }}</strong>
              </v-col>
              <v-col cols="12" md="6">
                <span
                  v-if="clients.filter(c => c.value == user.business_code)[0]"
                >
                  {{
                    clients.filter(c => c.value == user.business_code)[0].text
                  }}
                </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <strong>{{ $phraser.set('updated at:') }}</strong>
              </v-col>
              <v-col cols="12" md="6">
                <span>{{ formatTime(user.updated_at) }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <strong>{{ $phraser.set('created at:') }}</strong>
              </v-col>
              <v-col cols="12" md="6">
                <span>{{ formatTime(user.created_at) }}</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="8">
        <base-material-card color="indigo">
          <template v-slot:heading>
            <div class="text-h3 font-weight-light" color="white">
              {{ $phraser.set('edit details') }}
            </div>
          </template>
        </base-material-card>
        <v-form
          v-if="Object.keys(form).length"
          ref="form"
          @submit.prevent="submit"
        >
          <v-container class="py-0">
            <v-row justify="space-around">
              <v-col cols="12" md="4">
                <base-smart-input
                  :model.sync="form.first_name"
                  :label="$phraser.set(`first name`)"
                  min="2"
                  max="25"
                  required
                />
              </v-col>

              <v-col cols="12" md="4">
                <base-smart-input
                  :model.sync="form.last_name"
                  :label="$phraser.set(`last name`)"
                  min="2"
                  max="25"
                  required
                />
              </v-col>

              <v-col cols="12" md="4">
                <base-smart-input
                  :model.sync="form.email"
                  :label="$phraser.set(`email`)"
                  type="email"
                />
              </v-col>

              <v-row justify="center" class="w-100">
                <v-col cols="12" md="6">
                  <base-smart-input
                    :model.sync="form.phone"
                    :label="$phraser.set(`phone`)"
                    length="10"
                    numberic
                    type="tel"
                  />
                </v-col>
              </v-row>

              <v-col cols="12" md="4">
                <v-select
                  v-model="form.business_code"
                  :items="clients"
                  :label="$t('client')"
                />
              </v-col>

              <v-col cols="12" md="4">
                <v-select
                  v-model="form.locale"
                  :items="languages"
                  :label="$t('locale')"
                />
              </v-col>

              <v-col v-if="!!editSMS" cols="12" md="4">
                <v-select
                  v-model="form.enablesms"
                  :items="sms_packages"
                  :label="$phraser.set('sms package')"
                />
              </v-col>

              <v-row justify="center">
                <v-col cols="12" md="4">
                  <v-radio-group v-model="form.smsable" row>
                    <label for="">{{ $phraser.set('emailnots:') }}</label>
                    <v-radio :label="$t('yes')" :value="1" />
                    <v-radio :label="$t('no')" :value="0" />
                  </v-radio-group>
                </v-col>

                <v-col cols="12" md="4">
                  <v-radio-group v-model="form.emailable" row>
                    <label for="">{{ $phraser.set('smsnots:') }}</label>
                    <v-radio :label="$t('yes')" :value="1" />
                    <v-radio :label="$t('no')" :value="0" />
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-row>
            <v-col cols="12" class="text-right">
              <v-btn
                color="success"
                class="mr-0 text-h4"
                block
                type="submit"
                :loading="loading"
              >
                {{ $phraser.set('update details') }}
              </v-btn>
            </v-col>
          </v-container>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment'
export default {
  name: 'UserDetails',

  data: () => ({
    form: {},
    initiated: false,
    submitted: false,
    loading: false,
    languages: [
      {
        text: 'עברית',
        value: 'he',
      },
      {
        text: 'English',
        value: 'en',
      },
    ],
    sms_packages: [],
  }),

  computed: {
    user () {
      return this.$store.getters['user/user']
    },
    phoneToken () {
      return this.$store.getters['confirmphone/temporary']
    },
    clients () {
      return [];
      // const clients = this.$store.getters['clients/allAndMe']
      // return clients.map(client => {
      //   return { value: client.code, text: client.name }
      // })
    },
    editSMS () {
      return this.$store.getters['user/can']('edit_sms')
    },
  },

  watch: {
    phoneToken (token, old) {
      if (!old && token && this.submitted) {
        this.form.phone_token = token
        this.loading = true
        setTimeout(() => {
          this.$store.dispatch('users/editUser', this.form)
        }, 2000)
      }
    },
  },

  created () {
    moment.locale(this.$i18n.locale)
  },

  mounted () {
    if (this.user) {
      this.setForm().then(() => {
        this.$refs.form.resetValidation()
      })
    }
    this.sms_packages.push({
      text: this.$t('without'),
      value: 0,
    })
    this.sms_packages.push({
      text: this.$t('unlimited'),
      value: 1,
    })
  },

  methods: {
    async setForm () {
      const params = [
        'email',
        'first_name',
        'last_name',
        'phone',
        'business_code',
        'locale',
        'enablesms',
        'emailable',
        'smsable',
      ]
      const form = {}
      for (const param of params) {
        form[param] = this.user[param] ?? ''
      }
      this.form = form
      this.form.slug = this.user.slug
    },
    async submit () {
      if (this.$refs.form.validate() && !this.loading) {
        if (this.form.phone !== this.user.phone) {
          await this.$store.dispatch('confirmphone/cancel')
          await this.$store.dispatch('confirmphone/start', {
            phone: this.form.phone,
            slug: this.user.slug,
          })
          this.submitted = true
        } else {
          await this.$store.dispatch('users/editUser', this.form)
          this.setForm()
        }
      }
    },
    formatTime (time) {
      return moment(time)._isValid ? moment(time).format('lll') : time
    },
  },
}
</script>
